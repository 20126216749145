import { useCallback, useMemo, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Field, Fields, PreviewContainer } from '@shared/views/form-dialog';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import MaterialTextField from '@pages/products/modal/MaterialTextField';
import Preview from '@pages/products/modal/Preview';
import { Fieldset } from '@pages/products/modal/fieldset';
import { ModelFormats } from '@pages/products/constants';
import TextureSizeController from '@pages/products/modal/TextureSizeController';
import useProductPreview from '@pages/products/hooks/useProductPreview';
import { materialPresetsApi } from '@api';
import TextField from '@pages/scenes/child-details/TextField';
import { getWireframeMaterial } from '@pages/products/utils';

const useStyles = makeStyles((theme) => ({
  groupWrapper: {
    padding: '20px 0',
  },
  groupLabel: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.common.darkGrey,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.green,
    },
  },
  previewContainer: {
    justifyContent: 'center',
    maxWidth: '722px',
    flexBasis: 'calc(100vh - 241px)', // 722px for full hd non full screen
  },
  leftSide: {
    overflowY: 'auto',
    height: '454px',
  },
}));

const VariantBuilderFieldset = ({ productData }) => {
  const classes = useStyles();

  const {
    control,
  } = useFormContext();

  const [object, setObject] = useState();
  const [gl, setGl] = useState();
  const [presetMaterials, setPresetMaterials] = useState({});

  const { renderLightPresetsController } = useProductPreview({ object, gl });

  const currentGroupsValue = useWatch({ control, name: 'groups' });
  const currentTextureSize = useWatch({ control, name: Fieldset.TextureSize });
  const currentEnvironment = useWatch({ control, name: Fieldset.Environment });

  const meshes = useMemo(() => {
    const result = [];
    if (!object) {
      return result;
    }
    object.traverse((element) => {
      if (element.isMesh) {
        result.push(element);
      }
    });

    return result;
  }, [object]);

  const materials = useMemo(() => currentGroupsValue.map((item, index) => (item.material?.id ? item : productData.materials[index])),
    [productData, currentGroupsValue]);

  const handleMaterialFieldOpen = useCallback(async (presetId) => {
    if (presetId && !presetMaterials[presetId]) {
      const data = await materialPresetsApi.getMaterialsByPresetId(presetId);

      setPresetMaterials((prev) => ({ ...prev, [presetId]: data.items }));
    }
  }, [presetMaterials]);

  const onClickHandler = useCallback((indexes) => {
    productData.materials.forEach((item, index) => {
      if (indexes.indexOf(index) > -1 && meshes[index]?.userData) {
        const wireframeMaterial = getWireframeMaterial();
        wireframeMaterial.wireframe = true;
        meshes[index].userData.wireframeToApply = wireframeMaterial;
      }
    });
  }, [productData, meshes]);

  const mouseLeaveHandler = useCallback((indexes) => {
    productData.materials.forEach((item, index) => {
      if (indexes.indexOf(index) > -1 && meshes[index]?.userData?.wireframeToApply) {
        meshes[index].userData.wireframeToApply.dispose();
        delete meshes[index].userData.wireframeToApply;
      }
    });
  }, [productData, meshes]);

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 5 } className={ classes.leftSide }>
        {
          productData.childrenConfig.map((item, index) => {
            const groupIndex = item.group?.id
              ? productData.materials.findIndex((i) => i.group.id === item.group.id)
              : index;
            const indexes = item.group?.id
              ? productData.materials.map((e, i) => (e.group.id === item.group.id ? i : ''))
                .filter(String)
              : [groupIndex];

            return (
              <div
                /* eslint-disable-next-line react/no-array-index-key */
                key={ index }
              >
                <div className={ classes.groupWrapper }>
                  <div
                    className={ classes.groupLabel }
                    onClick={ onClickHandler.bind(null, indexes) }
                    onMouseLeave={ mouseLeaveHandler.bind(null, indexes) }
                  >
                    {item.group?.name ?? 'Non-configurable Part'}
                  </div>
                </div>

                <Fields>
                  {item.preset ? (
                    <Field style={ { position: 'relative' } }>
                      <Controller
                        name={ `groups[${index}].material` }
                        control={ control }
                        defaultValue={ { name: '' } }
                        render={ ({ field }) => (
                          <MaterialTextField
                            { ...field }
                            label="Material"
                            onSelect={ () => {} }
                            onOpen={ handleMaterialFieldOpen.bind(null, item?.preset?.id) }
                            externalOptions={ presetMaterials?.[item?.preset?.id] ?? [] }
                            loading={ !presetMaterials?.[item?.preset?.id] }
                          />
                        ) }
                      />
                    </Field>
                  ) : (
                    <Field style={ { position: 'relative' } }>
                      <Controller
                        name={ `groups[${index}].material` }
                        control={ control }
                        defaultValue={ { name: '' } }
                        render={ ({ field }) => (
                          <TextField
                            { ...field }
                            value={ field.value?.name ?? '' }
                            label="Material"
                            disabled
                            readOnly
                          />
                        ) }
                      />
                    </Field>
                  )}
                </Fields>
              </div>
            );
          })
        }
      </Grid>

      <Grid item xs={ 7 }>
        <PreviewContainer className={ classes.previewContainer }>
          <Preview
            name={ Fieldset.ModelData }
            fileName={ productData.name }
            supportedFormats={ ModelFormats }
            generateThumbnail={ false }
            renderModelViewerExtraContent={ (object) => (
              <>
                { renderLightPresetsController(object) }
                <TextureSizeController name={ Fieldset.TextureSize } />
              </>
            ) }
            onLoad={ (object, camera, gl) => {
              setObject(object);
              setGl(gl);
            } }
            control={ control }
            materials={ materials }
            environment={ currentEnvironment }
            size={ currentTextureSize }
          />
        </PreviewContainer>
      </Grid>
    </Grid>
  );
};

export default VariantBuilderFieldset;
