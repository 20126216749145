import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import Tooltip from '@material-ui/core/Tooltip';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Chip from '@material-ui/core/Chip';
import { withStyles, makeStyles } from '@material-ui/core/styles';

import PageContainer from '@shared/components/page-parts/Container';
import PageHead from '@shared/components/page-parts/Head';
import PageContent from '@shared/components/page-parts/Content';
import TopNav from '@shared/components/top-nav/Nav';
import Button from '@shared/components/button';
import AlertDialog from '@shared/components/card-controls/AlertDialog';
import useAlertControl, {
  Types,
} from '@shared/components/card-controls/useAlertControl';
import PlusIcon from '@shared/icons/PlusIcon';
import EditIcon from '@resources/icons/EditIcon';
import DeleteIcon from '@resources/icons/DeleteIcon';

import { deleteAttributeDefinition, loadIsUsed } from '@store/attribute-definitions/actions';
import { selectAttributeDefinitions } from '@store/attribute-definitions/selectors';

import ActionType from '../modal/ActionType';
import AttributeDefinitionModal from '../modal';
import { selectCurrentCompany, selectSortingId } from '@store/app/selectors';
import Breadcrumbs from '@shared/components/breadcrumbs';
import sortData from '@app/sort';

const useStyles = makeStyles((theme) => ({
  table: {
    '& .MuiTableCell-head': {
      fontWeight: 'bold',
    },
    '& .MuiTableCell-root': {
      fontSize: '1.2rem',
    },
    '& .MuiTableCell-root[disabled]': {
      color: 'rgba(0, 0, 0, 0.26)',
    },
  },
  chipsStack: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

const BREADCRUMBS_ITEMS = [{ name: 'Attribute definitions' }];
const MAX_CHIPS_SIZE = 7;

const AttributeDefinitionsList = () => {
  const dispatch = useDispatch();
  const attributeDefinitions = useSelector(selectAttributeDefinitions);
  const sortingId = useSelector(selectSortingId);
  const company = useSelector(selectCurrentCompany);

  const [modalOpen, setModalOpen] = useState(false);
  const [modalType, setModalType] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [attribute, setAttribute] = useState(null);

  const classes = useStyles();

  const sortedAttributeDefinitions = useMemo(() => {
    if (attributeDefinitions) {
      return sortData(attributeDefinitions, sortingId, 'name');
    }
    return [];
  }, [attributeDefinitions, sortingId]);

  const flatAttributes = useMemo(() => {
    if (attributeDefinitions) {
      const flatMap = {};
      for (const ad of attributeDefinitions) {
        flatMap[ad.id] = ad.attributes.map((attribute) => attribute.value);
      }
      return flatMap;
    }
  }, [attributeDefinitions]);

  const [
    alertOpen,
    { handleAlertControlClick, handleAgree, handleDisagree, ariaAction, setTitle, title },
  ] = useAlertControl();

  const onAttributeDefinitionCreate = () => {
    setModalOpen(true);
    setModalData(null);
    setModalType(ActionType.CREATE);
  };

  const onAttributeDefinitionEdit = (attributeDefinition) => {
    setModalOpen(true);
    setModalType(ActionType.UPDATE);
    setModalData(attributeDefinition);
  };

  const isUsedData = useSelector((state) => state.attributeDefinitions.isUsedData);

  useEffect(() => {
    if (isUsedData?.length > 0 && attribute?.name) {
      const entityTypes = attribute?.entityTypes.map((et) => et).join(', ');
      setTitle(`Are you sure you want to delete ${attribute?.name} permanently? It is used in Attributes values in: ${entityTypes}`);
      handleAlertControlClick(Types.Delete, () => dispatch(deleteAttributeDefinition(attribute?.id)))();
    }
  }, [isUsedData]);

  const onAttrbuteDefinitionDelete = async (attributeDefinition) => {
    setAttribute(attributeDefinition);
    dispatch(loadIsUsed(attributeDefinition.id));
    setTitle(`Are you sure you want to delete ${attributeDefinition.name} permanently?`);
    handleAlertControlClick(Types.Delete, () => dispatch(deleteAttributeDefinition(attributeDefinition.id)))();
  };

  const onModalClose = () => {
    setModalOpen(false);
  };

  const CustomTooltip = withStyles((theme) => ({
    tooltip: {
      //make changes if needed
      fontSize: 11,
    },
  }))(Tooltip);

  return (
    <PageContainer>
      <PageHead>
        <TopNav>
          <Breadcrumbs items={ BREADCRUMBS_ITEMS } />
          <Button
            leftPlace={ <PlusIcon /> }
            onClick={ onAttributeDefinitionCreate }
          >
            Create new
          </Button>
        </TopNav>
        <AttributeDefinitionModal
          open={ modalOpen }
          type={ modalType }
          attributeDefinition={ modalData }
          onClose={ onModalClose }
        />
        <AlertDialog
          open={ alertOpen }
          ariaAction={ ariaAction }
          title={ title }
          onAgree={ handleAgree }
          onDisagree={ handleDisagree }
        />
      </PageHead>
      <PageContent>
        <TableContainer component={ Paper }>
          <Table className={ classes.table }>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Values</TableCell>
                <TableCell>Entity Types</TableCell>
                <TableCell align="right">Actions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {sortedAttributeDefinitions.map((attrDef) => {
                const disabled = !company.default && !!attrDef.defaultContent;
                const predefined = !!attrDef.predefined;
                return (
                  <TableRow key={ attrDef.id }>
                    <TableCell disabled={ disabled }>{attrDef.name}</TableCell>
                    <CustomTooltip title={ flatAttributes[attrDef.id].join(',') } placement="bottom-start">
                      <TableCell disabled={ disabled }>
                        <div className={ classes.chipsStack }>
                          {flatAttributes[attrDef.id].slice(0, MAX_CHIPS_SIZE).map((value) => (
                            <Chip size="small" label={ value } key={ value } />
                          ))}
                          {flatAttributes[attrDef.id].length > MAX_CHIPS_SIZE ? (
                            <Chip size="small" label={ `+ ${flatAttributes[attrDef.id].length - MAX_CHIPS_SIZE}` } />
                          ) : null}
                        </div>
                      </TableCell>
                    </CustomTooltip>
                    <TableCell disabled={ disabled }>{attrDef.entityTypes.map((entityType) => entityType).join(', ')}</TableCell>
                    <TableCell align="right">
                      <IconButton disabled={ disabled } onClick={ () => onAttributeDefinitionEdit(attrDef) }>
                        <EditIcon />
                      </IconButton>
                      <IconButton disabled={ disabled || predefined } onClick={ () => onAttrbuteDefinitionDelete(attrDef) }>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContent>
    </PageContainer>
  );
};

export default AttributeDefinitionsList;
