import { useState, useEffect, useCallback, useRef } from 'react';
import { useSelector } from 'react-redux';
import ModelsList from './ModelsList';
import SharedDialog from '@shared/components/dialog/Dialog';
import DialogSearchInput from '../ListDialog/DialogSearchInput';
import DialogContent from '../ListDialog/DialogContent';
import DialogActions from '../ListDialog/DialogActions';
import CloseButton from '../ListDialog/CloseButton';
import SubmitButton from '../ListDialog/SubmitButton';
import useSelectItem from '../ListDialog/useSelectItem';
import { selectProducts } from '@store/products/selectors';
import TopNav from '@shared/components/top-nav/Nav';
import filterData from '@app/filter/filterData';
import Button from '@shared/components/button';
import { makeStyles } from '@material-ui/core/styles';
import VariantBuilderForm from '@pages/scenes/edit/ModelsListDialog/VariantBuilderForm';

const useStyles = makeStyles(() => ({
  backButton: {
    maxWidth: '140px',
  },
  builderDialogContent: {
    backgroundColor: '#FFFFFF',
  },
}));

const ModelsListDialog = ({
  open,
  onSubmit,
  onClose,
}) => {
  const classes = useStyles();

  const models = useSelector(selectProducts);

  const [model, setModel] = useState(null);
  const [showVariantsFor, setShowVariantsFor] = useState(null);
  const [showVariantBuilderFor, setShowVariantBuilderFor] = useState(null);
  const [selectedVariant, setSelectedVariant] = useState(null);
  const [shouldScrollToTop, setShouldScrollToTop] = useState(false);
  const [search, setSearch] = useState('');

  const variantBuilderFormRef = useRef();

  const handleCloseClick = () => {
    onClose();
    setModel(null);
    setSelectedVariant(null);
    setShowVariantsFor(null);
    setShowVariantBuilderFor(null);
  };

  const handleChooseClick = () => {
    if (showVariantBuilderFor) {
      variantBuilderFormRef.current?.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }));
      return;
    }

    let entityToSubmit = model;

    if (showVariantsFor) {
      entityToSubmit = selectedVariant;
    }

    onSubmit(entityToSubmit);
    setModel(null);
    setSelectedVariant(null);
    setShowVariantsFor(null);
    setShowVariantBuilderFor(null);
  };

  const handleVariantBuilderSubmit = useCallback((data) => {
    const entityToSubmit = {
      ...showVariantBuilderFor,
      parent: showVariantBuilderFor,
      materials: showVariantBuilderFor.materials.map((item, index) => ({
        ...item,
        material: data.groups[index].material?.id ? data.groups[index].material : item.material,
      })),
    };

    onSubmit(entityToSubmit, {
      materials: data.groups,
    });
    setModel(null);
    setSelectedVariant(null);
    setShowVariantsFor(null);
    setShowVariantBuilderFor(null);
  }, [onSubmit, showVariantBuilderFor]);

  const handleSearchChange = ({ target }) => {
    setSearch(target.value);
  };

  const [contentRef, handleSelectModel] = useSelectItem(setModel);

  const handleItemClick = useCallback((...args) => {
    if (showVariantsFor) {
      setSelectedVariant(args[0]);
    } else {
      handleSelectModel(...args);
    }
  }, [showVariantsFor, handleSelectModel]);

  const handleShowVariantsClick = useCallback((product) => {
    if (product.childProducts.length) {
      setShowVariantsFor(product);
    } else {
      setShowVariantBuilderFor(product);
    }
  }, []);

  const scrollToTop = () => {
    setShouldScrollToTop(true);
  };

  useEffect(() => {
    if (shouldScrollToTop && contentRef.current) {
      contentRef.current.scrollTop = 0;
      setShouldScrollToTop(false);
    }
  }, [shouldScrollToTop, contentRef]);

  return (
    <SharedDialog
      maxWidth="md"
      fullWidth
      onClose={ handleCloseClick }
      open={ open }
    >
      {!showVariantBuilderFor ? (
        <TopNav>
          <DialogSearchInput
            value={ search }
            onChange={ handleSearchChange }
          />
        </TopNav>
      ) : null}

      <DialogContent ref={ contentRef } className={ showVariantBuilderFor ? classes.builderDialogContent : undefined }>
        {showVariantBuilderFor ? (
          <VariantBuilderForm
            ref={ variantBuilderFormRef }
            productData={ showVariantBuilderFor }
            onBackClick={ setShowVariantBuilderFor.bind(this, null) }
            onSubmit={ handleVariantBuilderSubmit }
          />
        ) : (
          <ModelsList
            models={ showVariantsFor ? filterData(showVariantsFor.childProducts, search) : filterData(models.items, search) }
            selectedModel={ showVariantsFor ? selectedVariant : model }
            filter={ showVariantsFor && (
              <Button
                className={ classes.backButton }
                onClick={ setShowVariantsFor.bind(this, null) }
              >
                Back
              </Button>
            ) }
            onItemClick={ handleItemClick }
            onShowVariantsClick={ handleShowVariantsClick }
            scrollToTop={ scrollToTop }
            showVariantsFor={ showVariantsFor }
          />
        )}
      </DialogContent>
      <DialogActions>
        <CloseButton onClick={ handleCloseClick }>
          Cancel
        </CloseButton>
        <SubmitButton disabled={ !model } onClick={ handleChooseClick }>
          Choose
        </SubmitButton>
      </DialogActions>
    </SharedDialog>
  );
};

export default ModelsListDialog;
