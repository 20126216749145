import { forwardRef } from 'react';
import MuiPaper from '@material-ui/core/Paper';

const Paper = forwardRef(({
  children, className, component, noValidate, onSubmit,
}, ref) => (
  <MuiPaper
    ref={ ref }
    className={ className }
    component={ component }
    noValidate={ noValidate }
    onSubmit={ onSubmit }
  >
    {children}
  </MuiPaper>
));

export default Paper;
