const ENDPOINT = '/material-presets';

class MaterialPresetsApi {
  constructor(http) {
    this.http = http;
  }

  getMaterialPresets = async () => {
    const data = await this.http.get(ENDPOINT);

    return data;
  };

  createMaterialPreset = async (presetData) => {
    const data = await this.http.post(ENDPOINT, presetData, { onError: (error) => {
      throw error;
    } });

    return data;
  };

  editMaterialPreset = async (presetData) => {
    const data = await this.http.put(`${ENDPOINT}/${presetData.id}`, presetData, { onError: (error) => {
      throw error;
    } });

    return data;
  };

  deleteMaterialPreset = (id) => this.http.delete(`${ENDPOINT}/${id}`, { onError: (error) => {
    throw error;
  } });

  getMaterialsByPresetId = async (id) => {
    const data = await this.http.get(`${ENDPOINT}/${id}/materials`);

    return data;
  };
}

export default MaterialPresetsApi;
