import { forwardRef } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@shared/components/button';
import { Form } from '@shared/views/form-dialog';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Fieldset, getDefaultLightPreset } from '@pages/products/modal/fieldset';
import VariantBuilderFieldset from '@pages/scenes/edit/ModelsListDialog/VariantBuilderFieldset';

const useStyles = makeStyles((theme) => ({
  backButton: {
    maxWidth: '140px',
  },
  groupWrapper: {
    padding: '20px 0',
  },
  groupLabel: {
    fontWeight: 500,
    fontSize: '14px',
    lineHeight: '21px',
    color: theme.palette.common.darkGrey,
    textDecoration: 'underline',
    cursor: 'pointer',
    '&:hover': {
      color: theme.palette.common.green,
    },
  },
  formRoot: {
    boxShadow: 'none',
  },
}));

const defaultTextureSize = 1024;

const VariantBuilderForm = forwardRef(({ productData, onBackClick, onSubmit }, ref) => {
  const classes = useStyles();

  const allEnvironments = useSelector((state) => state.scenes.environments.items);

  const form = useForm({
    defaultValues: {
      groups: productData.childrenConfig.map((item) => ({ material: item.preset ? { name: '' } : item.material })),
      [Fieldset.Model]: {
        ...productData.model,
        data: {
          blob: productData.model?.data,
          thumbnail: productData.model?.data?.thumbnail,
          dataType: productData.model?.dataType,
          scale: productData.model?.scale,
        },
      },
      [Fieldset.Environment]: getDefaultLightPreset(allEnvironments),
      [Fieldset.TextureSize]: defaultTextureSize,
    },
  });

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        <Button
          className={ classes.backButton }
          onClick={ onBackClick }
        >
          Back
        </Button>
      </Grid>
      <Grid item xs={ 12 }>
        <Form
          ref={ ref }
          className={ classes.formRoot }
          open
          onSubmit={ form.handleSubmit(onSubmit) }
        >
          <FormProvider { ...form }>
            <VariantBuilderFieldset productData={ productData } />
          </FormProvider>
        </Form>
      </Grid>
    </Grid>
  );
});

export default VariantBuilderForm;
