import { useRef, useMemo, memo, Suspense } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import { OrbitControls, Stage, PerspectiveCamera } from '@react-three/drei';
import useLoadObject from './hooks/useLoadObject';
import Backdrop from '@shared/components/backdrop';
import CircularProgress from '@shared/components/circular-progress';
import ui from './uiTunnel';

function StageModel({
  value,
  materials,
  scale,
  size,
  onLoad,
  onFrame,
  isInitialized,
}) {
  const gl = useThree((state) => state.gl);
  const camera = useThree((state) => state.camera);
  const scene = useThree((state) => state.scene);
  const object = useLoadObject(value, materials, value.scale || scale, size, (object) => {
    onLoad(object, camera, gl, scene);
  }, isInitialized);

  useFrame(() => {
    if (onFrame) {
      onFrame(object);
    }
  });

  return <primitive object={ object } scale={ value.scale || scale } />;
}

const Scene = ({ value, materials, environment, scale, size, onLoad, onFrame }) => {
  const controlsRef = useRef();
  const cameraRef = useRef();
  const isInitialized = useRef(false);

  const envConfig = useMemo(() => (environment ? {
    files: `${environment.url}?name=${environment.name.replace(' ', '_')}.${environment.type}`,
  } : null), [environment]);

  const handleLoad = (...args) => {
    if (onLoad) {
      onLoad(...args);
      isInitialized.current = true;
    }
  };

  const fallback = useMemo(() => {
    return (
      <ui.In>
        <Backdrop>
          <CircularProgress size="large" />
        </Backdrop>
      </ui.In>
    );
  }, []);

  return (
    <>
      <PerspectiveCamera
        ref={ cameraRef }
        makeDefault
      />
      <OrbitControls ref={ controlsRef } rotateSpeed={ 1.5 } makeDefault />
      <Suspense fallback={ fallback }>
        <Stage
          key={ value?.blob }
          adjustCamera={ 1 }
          intensity={ 0.5 }
          preset="rembrandt"
          shadows={ {
            type: 'contact',
            offset: 0,
          } }
          environment={ envConfig }
          center={ {
            disable: true,
          } }
        >
          <StageModel
            value={ value }
            materials={ materials }
            scale={ scale }
            size={ size }
            onLoad={ handleLoad }
            onFrame={ onFrame }
            isInitialized={ isInitialized.current }
          />
        </Stage>
      </Suspense>
    </>
  );
};

export default memo(Scene);
