import { useCallback } from 'react';
import Model from '@pages/models/list/grid/Model';
import CardsList from '@shared/components/cards-list/CardsList';
import CardItem from '@shared/components/cards-list/CardItem';
import withSelectable from '@shared/components/cards-list/withSelectable';
import NoElementsNote from '@shared/components/cards-list/NoElementsNote';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DotsTreeIcon from '@resources/icons/DotsTreeIcon';

const useStyles = makeStyles(() => ({
  variantsButton: {
    position: 'absolute',
    top: '12px',
    right: '12px',
    width: '24px',
    height: '24px',
    padding: '4.5px',
    borderRadius: '50%',
    fontSize: '15px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    color: '#9DA3B2',
    backgroundColor: '#FFFFFF',

    '&:hover': {
      backgroundColor: '#F5F5F5',
      color: '#00C98F',
    },
  },
}));

const SelectableModel = withSelectable(Model);

const ModelsList = ({ models, selectedModel, filter, onItemClick, onShowVariantsClick, scrollToTop, showVariantsFor }) => {
  const classes = useStyles();

  const handleShowVariantsClick = (model) => {
    onShowVariantsClick(model);
    if (!showVariantsFor) {
      scrollToTop();
    }
  };

  const getModelControls = useCallback((model) => (
    (model.childProducts.length || model.childrenConfig?.length) ? (
      <div className={ classes.variantsButton } onClick={ () => handleShowVariantsClick(model) }>
        <DotsTreeIcon size="smallest" />
      </div>
    ) : null
  ), [onShowVariantsClick, classes, showVariantsFor, scrollToTop]);

  return (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 }>
        {filter}
      </Grid>
      <Grid item xs={ 12 }>
        {models.length > 0 ? (
          <CardsList>
            {models.map((model) => (
              <CardItem key={ model.id } xl={ 3 } lg={ 4 } md={ 6 }>
                <SelectableModel
                  item={ model }
                  selected={ selectedModel === model }
                  onClick={ onItemClick }
                  defaultClickable
                  controls={ getModelControls(model) }
                />
              </CardItem>
            ))}
          </CardsList>
        ) : (
          <NoElementsNote>Nothing found!</NoElementsNote>
        )}
      </Grid>
    </Grid>
  );
};

export default ModelsList;
