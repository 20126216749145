import { stringify } from 'query-string';
import { convertFileToBase64 } from '@shared/utils';
import { defaultScene } from '@shared/constants';
import { omitBy, isNil } from 'lodash';
import { translationsToFields, translatedFieldsToArray } from '@shared/utils/translations';

const ENDPOINT = '/scenes';

const convertScene = (scene) => {
  const {
    id,
    name,
    url,
    createdAt,
    updatedAt,
    category,
    interior,
    environment,
    lights,
    shadows,
    models,
    attributes,
    defaultContent,
    hiddenForCompany,
    translations,
    globalLight,
  } = scene;

  const adjustedModels = models.map((item) => (item.model?.id && item.modelConfig?.materials?.length ? ({
    ...item,
    model: {
      ...item.model,
      parentProduct: item.model.id,
      materials: item.model.materials.map((materialItem, index) => (item.modelConfig.materials[index]?.material?.id
        ? { ...materialItem, material: item.modelConfig.materials[index].material } : materialItem)),
    },
  }) : item));

  return {
    ...defaultScene,
    ...omitBy(
      {
        id,
        name,
        url,
        category,
        createdAt,
        updatedAt,
        interior,
        lights,
        models: adjustedModels,
        attributes,
        defaultContent,
        hiddenForCompany,
        env: environment?.hdri,
        envIntensity: environment?.intensity,
        envRotation: environment?.rotation,
        softShadows: !!shadows?.softShadows,
        samples: shadows?.softShadows?.samples,
        size: shadows?.softShadows?.size,
        focus: shadows?.softShadows?.focus,
        shadowOpacity: shadows?.shadowOpacity,
        allModelsLocked: true,
        translatedFields: translationsToFields(translations),
        globalLightColor: globalLight?.color,
        globalLightIntensity: globalLight?.intensity,
      },
      isNil,
    ),
  };
};

class ScenesApi {
  constructor(http) {
    this.http = http;
  }

  getScenes = async () => {
    const query = {
      populate: ['interior', 'environment.hdri'],
    };
    const { items, total } = await this.http.get(`${ENDPOINT}?${stringify(query)}`);
    return { items: items.map(convertScene), total };
  }

  getEnvironments = () => this.http.get('/hdris');

  getScene = async (id) => {
    const query = {
      populate: ['models.model', 'models.modelConfig.materials.material', 'interior', 'environment.hdri'],
    };
    const data = await this.http.get(`${ENDPOINT}/${id}?${stringify(query)}`);
    return convertScene(data);
  }

  createScene = async (scene) => {
    await this.populateEnvironment(scene);

    const translations = translatedFieldsToArray(scene.translatedFields);

    const createSceneParams = {
      name: scene.name,
      url: scene.url,
      interior: scene.interior ? scene.interior.id : null,
      environment: {
        hdri: scene.env ? scene.env.id : null,
        intensity: scene.envIntensity,
        rotation: scene.envRotation,
      },
      lights: scene.lights.map((item) => ({
        ...item,
        _id: undefined,
      })),
      shadows: {
        softShadows: (scene.softShadows
          ? {
            samples: scene.samples,
            focus: scene.focus,
            size: scene.size,
          }
          : null
        ),
        shadowOpacity: scene.shadowOpacity,
      },
      models: scene.models.map((item) => ({
        ...item,
        model: item.model ? item.model.id : null,
        _id: undefined,
      })),
      attributes: scene.attributes,
      translations,
      globalLight: {
        type: 'ambientLight',
        color: scene.globalLightColor,
        intensity: scene.globalLightIntensity,
      },
    };

    if (scene.image) {
      createSceneParams.image = {
        src: scene.image.src,
      };
    }

    const query = {
      populate: ['models.model', 'models.modelConfig.materials.material', 'interior', 'environment.hdri'],
    };

    const data = await this.http.post(`${ENDPOINT}?${stringify(query)}`, createSceneParams);
    return convertScene(data);
  }

  editScene = async (scene) => {
    const query = {
      populate: ['models.model', 'models.modelConfig.materials.material', 'interior', 'environment.hdri'],
    };

    await this.populateEnvironment(scene);

    const translations = translatedFieldsToArray(scene.translatedFields);

    const updateSceneProps = {
      id: scene.id,
      name: scene.name,
      interior: scene.interior ? scene.interior.id : null,
      environment: {
        hdri: scene.env && scene.env.id ? scene.env.id : null,
        intensity: scene.envIntensity,
        rotation: scene.envRotation,
      },
      lights: scene.lights,
      shadows: {
        softShadows: (scene.softShadows
          ? {
            samples: scene.samples,
            focus: scene.focus,
            size: scene.size,
          }
          : null
        ),
        shadowOpacity: scene.shadowOpacity,
      },
      models: scene.models.map((item) => ({
        ...item,
        model: item.model ? item.model.id || item.model : null,
      })),
      attributes: scene.attributes,
      defaultContent: scene.defaultContent,
      translations,
      globalLight: {
        type: 'ambientLight',
        color: scene.globalLightColor,
        intensity: scene.globalLightIntensity,
      },
    };

    if (scene.image) {
      updateSceneProps.image = {
        src: scene.image.src,
      };
    }

    const data = await this.http.put(`${ENDPOINT}/${scene.id}?${stringify(query)}`, updateSceneProps);
    return convertScene(data);
  }

  async populateEnvironment(scene) {
    if (scene.env && scene.env.image) {
      const data = await this.http.post('/hdris', scene.env);
      // eslint-disable-next-line no-await-in-loop
      const picture64 = await convertFileToBase64(scene.env.image);
      // eslint-disable-next-line no-await-in-loop
      const dataUpload = await this.http.post(`/hdris/${data.id}/upload`, {
        src: picture64,
        title: `${scene.env.image.name}`,
        type: scene.env.type,
      });
      data.url = dataUpload.url;
      scene.env = data;
    }
  }

  deleteScene = (id) => this.http.delete(`${ENDPOINT}/${id}`)

  deleteEnvironment = (id) => this.http.delete(`hdris/${id}`)

  getBackgroundScenes = (backgroundId) => this.http.get(`/interiors/${backgroundId}/scenes`)

  hideScene = (id) => this.http.put(`${ENDPOINT}/${id}/hide`)

  getScenesItemsCounts = () => this.http.get(`${ENDPOINT}/counts`);
}

export default ScenesApi;
